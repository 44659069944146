import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminAccessComponent, ForgotPasswordComponent, TwoFactorComponent } from '@mt-ng2/login-module';

import { CreateUserComponent } from '@common/create-user/create-user.component';
import { AdminLoginComponent } from '@common/login/admin-login.component';
import { MemberLoginComponent } from '@common/login/member-login.component';
import { ResetPasswordOverrideComponent } from '@common/reset-password-override/reset-password-override.component';
import { MtCanLoadAuthGuard, MtTwoFactorGuard } from '@mt-ng2/auth-module';
import { appPaths } from './admin-portal/default-routes/app-paths.library';

const appRoutes: Routes = [
    { path: appPaths.loginAdmin, component: AdminLoginComponent },
    { path: appPaths.login, component: MemberLoginComponent },
    { path: appPaths.createMember, component: CreateUserComponent },
    
    { path: appPaths.forgotPassword, component: ForgotPasswordComponent , title: 'Forgot Password' },
    { path: appPaths.resetPassword, component: ResetPasswordOverrideComponent },
    { canActivate: [MtTwoFactorGuard], path: appPaths.verify2FA, component: TwoFactorComponent },
    { path: appPaths.adminAccess, component: AdminAccessComponent , title: 'Admin Access' },
    { canLoad: [MtCanLoadAuthGuard], path: '', loadChildren: () => import('./admin-portal/admin-portal.module').then((m) => m.AdminPortalModule) },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
})
export class AppRoutingModule {}
