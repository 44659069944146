import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IUser } from '../interfaces/user';
import { IAddress } from '../interfaces/address';
import { IAuthUser } from '../interfaces/auth-user';
import { IImage } from '../interfaces/image';

export interface IUserDynamicControlsParameters {
    formGroup?: string;
    authUsers?: IAuthUser[];
    images?: IImage[];
    addresses?: IAddress[];
}

export class UserDynamicControls {

    formGroup: string;
    authUsers?: IAuthUser[];
    images?: IImage[];
    addresses?: IAddress[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private user?: IUser, additionalParameters?: IUserDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'User';
        this.authUsers = additionalParameters && additionalParameters.authUsers || undefined;
        this.images = additionalParameters && additionalParameters.images || undefined;
        this.addresses = additionalParameters && additionalParameters.addresses || undefined;

        this.Form = {
            AddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address',
                name: 'AddressId',
                options: this.addresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.AddressId || null,
            }),
            ApplicantNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Applicant Number',
                name: 'ApplicantNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.user && this.user.hasOwnProperty('ApplicantNumber') && this.user.ApplicantNumber != null ? this.user.ApplicantNumber.toString() : '',
            }),
            AuthUserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Auth User',
                name: 'AuthUserId',
                options: this.authUsers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.user && this.user.AuthUserId || null,
            }),
            BookingNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Booking Number',
                name: 'BookingNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.user && this.user.hasOwnProperty('BookingNumber') && this.user.BookingNumber != null ? this.user.BookingNumber.toString() : '',
            }),
            Email: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email',
                name: 'Email',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50), Validators.email ],
                validators: { 'required': true, 'maxlength': 50, 'email': true },
                value: this.user && this.user.hasOwnProperty('Email') && this.user.Email != null ? this.user.Email.toString() : '',
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.user && this.user.hasOwnProperty('FirstName') && this.user.FirstName != null ? this.user.FirstName.toString() : '',
            }),
            ImageId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Image',
                name: 'ImageId',
                options: this.images,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.ImageId || null,
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.user && this.user.hasOwnProperty('LastName') && this.user.LastName != null ? this.user.LastName.toString() : '',
            }),
            MarinerReferenceNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mariner Reference Number',
                name: 'MarinerReferenceNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.user && this.user.hasOwnProperty('MarinerReferenceNumber') && this.user.MarinerReferenceNumber != null ? this.user.MarinerReferenceNumber.toString() : '',
            }),
            PrizmId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Prizm',
                name: 'PrizmId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.PrizmId || null,
            }),
            TwoFactorEnabled: new DynamicField({
                formGroup: this.formGroup,
                label: 'Two Factor Enabled',
                name: 'TwoFactorEnabled',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.user && this.user.hasOwnProperty('TwoFactorEnabled') && this.user.TwoFactorEnabled != null ? this.user.TwoFactorEnabled : true,
            }),
        };

        this.View = {
            AddressId: new DynamicLabel({
                label: 'Address',
                value: getMetaItemValue(this.addresses as unknown as IMetaItem[], this.user && this.user.hasOwnProperty('AddressId') ? this.user.AddressId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ApplicantNumber: new DynamicLabel({
                label: 'Applicant Number',
                value: this.user && this.user.hasOwnProperty('ApplicantNumber') && this.user.ApplicantNumber != null ? this.user.ApplicantNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            AuthUserId: new DynamicLabel({
                label: 'Auth User',
                value: getMetaItemValue(this.authUsers as unknown as IMetaItem[], this.user && this.user.hasOwnProperty('AuthUserId') ? this.user.AuthUserId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            BookingNumber: new DynamicLabel({
                label: 'Booking Number',
                value: this.user && this.user.hasOwnProperty('BookingNumber') && this.user.BookingNumber != null ? this.user.BookingNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Email: new DynamicLabel({
                label: 'Email',
                value: this.user && this.user.hasOwnProperty('Email') && this.user.Email != null ? this.user.Email.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            FirstName: new DynamicLabel({
                label: 'First Name',
                value: this.user && this.user.hasOwnProperty('FirstName') && this.user.FirstName != null ? this.user.FirstName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ImageId: new DynamicLabel({
                label: 'Image',
                value: getMetaItemValue(this.images as unknown as IMetaItem[], this.user && this.user.hasOwnProperty('ImageId') ? this.user.ImageId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            LastName: new DynamicLabel({
                label: 'Last Name',
                value: this.user && this.user.hasOwnProperty('LastName') && this.user.LastName != null ? this.user.LastName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            MarinerReferenceNumber: new DynamicLabel({
                label: 'Mariner Reference Number',
                value: this.user && this.user.hasOwnProperty('MarinerReferenceNumber') && this.user.MarinerReferenceNumber != null ? this.user.MarinerReferenceNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            PrizmId: new DynamicLabel({
                label: 'Prizm',
                value: this.user && this.user.PrizmId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            TwoFactorEnabled: new DynamicLabel({
                label: 'Two Factor Enabled',
                value: this.user && this.user.hasOwnProperty('TwoFactorEnabled') && this.user.TwoFactorEnabled != null ? this.user.TwoFactorEnabled : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
