import { userPathSlugs } from './user-paths.library';

export const appPathSlugs = {
    login: 'login',
    myProfile: 'my-profile',
};

export const appPaths = {
    adminAccess: 'adminaccess',
    createMember: 'createMember',
    forgotPassword: 'forgotpassword',
    home: 'posts',
    login: appPathSlugs.login,
    loginAdmin: `${appPathSlugs.login}/admin`,
    myProfile: `${userPathSlugs.root}/${appPathSlugs.myProfile}`,
    resetPassword: 'resetpassword',
    verify2FA: 'verify'
};
